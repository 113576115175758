import REQ from '@/app/REQ'
import editAnnexDlg from "@/dialg/editAnnexDlg.vue"
import addDocPrjtStaffDlg from "@/dialg/addDocPrjtStaffDlg.vue"
import {mapGetters, mapState} from "vuex";
import {upWFoundById} from "@/utils/xyhUtils";
import {DOC_ROLE,DOC_STATE,DOC_TYPE} from "@/consts";
import docOptions from "./docOptions"
import replaceDocFileDlg from "@/doc/replaceDocFileDlg.vue"
import {wordViewUrl} from "@/doc/consts"
import ADMREQ from "@/app/ADMREQ";

const {DocPathsSMap} = docOptions

export default {
    name: 'libDoc',
    props:['id'],
    components:{editAnnexDlg, addDocPrjtStaffDlg, replaceDocFileDlg,},

    data() {
        return {
            docPaths:DocPathsSMap,
            doc: {},
            annexes:[],
            tplt:{},
            fileType:'',
            fileUrl: '',
            docAnnex:null,
            docStaffs:[],
            amIAuthor:false,
            amIPM:false,
            amIDocStaff:false,
            amIPrjtStaff:false,
            stateN:'',
            dept:{},
        }
    },

    computed: {
        ...mapState({
            orgId: ({ curOrg }) => curOrg?.id,
            myId: ({curStaff}) => curStaff?.id,
        }),
        ...mapGetters(['amIAdmin']),
    },
    created() {
        this.getDoc()
    },

    methods: {
        getDoc() {
            this.req2Data(REQ.docInLib, {id:this.id},null, ({doc})=>{
                if(!doc.state) doc.state = DOC_STATE.remarking
                if(this.tplt) this.tplt.name = doc.tpltId ? this.tplt.name:this.tplt.name + '(项目默认)'
                doc.url = XyhConf.HglSHost + '/' + REQ.previewDoc + '?id=' + doc.id + '&orgId=' + this.orgId + '&t=' + new Date().getTime()
                if(!doc.type) doc.type = DOC_TYPE.sop
            })
        },

        preview(fileType,url){
            this.fileType = fileType
            this.fileUrl = wordViewUrl + encodeURIComponent(url)
        },

        renewDocFile(id){
            this.confirm('确认重新生成文档文件吗',()=>{
                this.req2Data(REQ.genDocFile,{id},{},()=>this.getDoc())
            })
        },
    },

}

import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
    name: "editAnnexDlg",
    mixins: [formDlgMix],
    props: ['oldAnnex','docId', 'prjtId', 'deptId'],

    data() {
        return {
            uploadUrl:XyhConf.HglSHost +'/' + REQ.putFile,
            name:'',
            name_E:'',
            file:'',
            num:'',
            files:[]
        }
    },

    watch: {
        working(){
            let {oldAnnex} = this
            if(oldAnnex){
                if(oldAnnex.file) this.files = [{name:'文件一.odc'}]
                Object.assign(this, {name:oldAnnex.name,name_E:oldAnnex.name_E, file:oldAnnex.file, num:oldAnnex.num,})
            }else {
                Object.assign(this, {name:'',name_E:'', file:'', num:'', files: []})
            }
        }
    },

    methods: {
        beforeUpload(file) {
            const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            const whiteList = ["doc", "docx",];
            if (whiteList.indexOf(fileSuffix) === -1) {
                this.$message("上传文件只能是 doc、docx格式", "error");
                return false;
            }
        },

        handleChange(file,fileList){
            if (fileList.length > 1) {
                fileList.splice(0, 1);
            }
        },

        successUpload(res){
            this.file = res.data.url
        },

        ok() {
            let {docId, prjtId, deptId, oldAnnex, name,name_E, file, num} =this
            if(this.chk2msg(name, '请填写姓名', file, '请上传word文档',)) return
            if(oldAnnex){
                this.reqThenBk(REQ.upDocAnnex, {id:oldAnnex.id, name,name_E, file, num})
            }else {
                this.reqThenBk(REQ.addDocAnnex, Object.assign({name,name_E, file, num},docId ? {docId}:(deptId ? {deptId}:{prjtId})))
            }
        },
    }
};

import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
	name: "replaceDocFileDlg",
	mixins: [formDlgMix],
	props: ['docFile','docId'],

	data() {
		return {
			uploadUrl:XyhConf.HglSHost +'/' + REQ.putFile,
			file:'',
			files:[]
		}
	},

	watch: {
		working(){
			let {docFile} = this
			if(docFile){
				if(docFile) this.files = [{name:'文件一.odc'}]
				Object.assign(this, {file:docFile,})
			}else {
				Object.assign(this, {file:'',files: []})
			}
		}
	},

	methods: {
		handleChange(file,fileList){
			if (fileList.length > 1) {
				fileList.splice(0, 1);
			}
		},

		successUpload(res){
			this.file = res.data.url
		},

		ok() {
			let {docId, file} =this
			if(this.chk2msg(file, '请上传文件',)) return
			this.reqThenBk(REQ.replaceDocFile, {id:docId, file})
		},
	}
};
